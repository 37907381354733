import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';

import { MatIconModule } from '@angular/material/icon';
// Material Navigation
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';

import { HeaderMenuComponent } from './shared/header-menu/header-menu.component';
import { LoginHeaderComponent } from './shared/login-header/login-header.component';
import { SideNavComponent } from './shared/side-nav/side-nav.component';
import { UploadImagesComponent } from './shared/upload-images/upload-images.component';

import { MatListModule } from '@angular/material/list';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportManagerModalComponent } from './shared/report-manager-modal/report-manager-modal.component';
import { CountryselectComponent } from './shared/countryselect/country-select.component';

import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatBadgeModule } from "@angular/material/badge";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';




const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


const materialModules = [
  // CdkStepperModule,
  CdkDropList,
  CdkDrag,
  CdkTableModule,
  CdkTreeModule,
  MatIconModule,
  MatToolbarModule,
  MatSidenavModule,
  MatMenuModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatDividerModule,
  MatSelectModule,
  MatListModule,
  MatExpansionModule,
  MatDialogModule,
  MatCardModule,
  MatTableModule,
  MatBadgeModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatChipsModule,
  MatToolbarModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatGridListModule,
  MatTabsModule,
  MatCheckboxModule,
  MatTreeModule,
  MatProgressSpinnerModule,
  DragDropModule,
  MatTooltipModule,

];
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportManagerComponent } from './shared/report-manager/report-manager.component';
import { AddReportModalComponent } from './shared/add-report-modal/add-report-modal.component';
import { AutocompleteSaveComponent } from './shared/inputs/autocomplete-save/autocomplete-save.component';
import { MultiselectAutocompleteSaveComponent } from './shared/inputs/multiselect-autocomplete-save/multiselect-autocomplete-save.component';
import { ResponseModalComponent } from './shared/response-modal/response-modal.component';
import { FileUploaderComponent } from './shared/file-uploader/file-uploader.component';
import { HomeComponent } from './Pages/home/home.component';
import { ProfileComponent } from './Pages/profile/profile.component';
import { MsalModule, MsalRedirectComponent, MsalInterceptor } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { DriveHeaderComponent } from './sharedV2/drive-header/drive-header.component';
import { DriveMegamenuComponent } from './sharedV2/drive-megamenu/drive-megamenu.component';
import { ManageComponent } from './Pages/manage/manage.component';
import { AddReportComponent } from './sharedV2/add-report/add-report.component';
import { AppConfig } from './models/app.config';
import { ReportTreeViewComponent } from './sharedV2/report-tree-view/report-tree-view.component';
import { AddReportMainComponent } from './Pages/add-report/add-report-main/add-report-main.component';
import { SnackbarComponent } from './sharedV2/snack-bar/snackbar/snackbar.component';
import { ErrorSnackbarComponent } from './sharedV2/snack-bar/fail-snackbar/error-snackbar/error-snackbar.component';
import { SubjectareaDragSortComponent } from './sharedV2/subjectarea-drag-sort/subjectarea-drag-sort.component';
import { NodeDragSortComponent } from './sharedV2/node-drag-sort/node-drag-sort.component';
import { UrlViewerComponent } from './sharedV2/url-viewer/url-viewer.component';
import { WarningModalComponent } from './sharedV2/warning-modal/warning-modal.component';
import { EditTreenodeModalComponent } from './sharedV2/edit-treenode-modal/edit-treenode-modal.component';
import { SearchGptComponent } from './sharedV2/search-gpt/search-gpt.component';
import { DisclosureComponent } from './sharedV2/disclosure/disclosure.component';
import { GpthelpComponent } from './sharedV2/gpthelp/gpthelp.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { TypewriterCardComponent } from './sharedV2/typewriter-card/typewriter-card.component';
import { BreadcrumbViewerComponent } from './shared/breadcrumb-viewer/breadcrumb-viewer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { TableBasicExample } from './shared/report-manager/deleteme/table-basic-example';
import { DriveMegamenuMobileComponent } from './sharedV2/drive-megamenu/drive-megamenu-mobile/drive-megamenu-mobile/drive-megamenu-mobile.component';
import { NewsInsightsEditorComponent } from './sharedV2/news-insights-editor/news-insights-editor.component';
import { DriveFeaturesViewerComponent } from './sharedV2/drive-features-viewer/drive-features-viewer.component';
import { FeaturedReportsEditorComponent } from './sharedV2/featured-reports-editor/featured-reports-editor.component';
import { ToolCalculatorsStaticViewerComponent } from './sharedV2/tool-calculators-static-viewer/tool-calculators-static-viewer.component';
declare let appConfigs: AppConfig;
localStorage.setItem("app-config", JSON.stringify(appConfigs));

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },

  ],
  declarations: [
    AppComponent,
    ReportViewerComponent,
    HeaderMenuComponent,
    LoginHeaderComponent,
    SideNavComponent,
    ReportManagerModalComponent,
    UploadImagesComponent,
    CountryselectComponent,
    ReportManagerComponent,
    AddReportModalComponent,
    AutocompleteSaveComponent,
    MultiselectAutocompleteSaveComponent,
    ResponseModalComponent,
    FileUploaderComponent,
    HomeComponent,
    ProfileComponent,
    DriveHeaderComponent,
    DriveMegamenuComponent,
    ManageComponent,
    AddReportComponent,
    ReportTreeViewComponent,
    AddReportMainComponent,
    SnackbarComponent,
    ErrorSnackbarComponent,
    SubjectareaDragSortComponent,
    NodeDragSortComponent,
    UrlViewerComponent,
    WarningModalComponent,
    EditTreenodeModalComponent,
    SearchGptComponent,
    DisclosureComponent,
    GpthelpComponent,
    BreadcrumbComponent,
    TypewriterCardComponent,
    BreadcrumbViewerComponent,
    TableBasicExample,
    DriveMegamenuMobileComponent,
    NewsInsightsEditorComponent,
    DriveFeaturesViewerComponent,
    FeaturedReportsEditorComponent,
    ToolCalculatorsStaticViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PowerBIEmbedModule,
    HttpClientModule,
    PowerBIEmbedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    [...materialModules],
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: appConfigs ? appConfigs?.clientId : '',
        authority: 'https://login.microsoftonline.com/6cacd170-f897-4b19-ac58-46a23307b80a',
        redirectUri: appConfigs ? appConfigs?.webUrl : '',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        [`${appConfigs?.apiUrl}/api/report/drive-reports`, [
          {
            httpMethod: "GET",
            scopes: [`${appConfigs?.scopes}`]
          }
        ]],
        [`${appConfigs?.apiUrl}/api/report/groups`, [
          {
            httpMethod: "GET",
            scopes: [`${appConfigs?.scopes}`]
          }
        ]],
        [`${appConfigs?.apiUrl}/api/report`, [
          {
            httpMethod: "GET",
            scopes: [`${appConfigs?.scopes}`]
          }
        ]]
      ])
    })
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
