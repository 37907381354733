import { Component } from '@angular/core';
import { SubjectArea } from 'src/app/models/subject-area';
import { Category } from 'src/app/models/category';
import { HttpService } from 'src/app/services/http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MegamenuService } from 'src/app/services/megamenu.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {
  subjectAreas: SubjectArea[] = [];

  selectedSubjectArea: SubjectArea | null = null;
  selectedCategory: Category | null = null;



  currentViewedReportId = '';

  constructor(public httpService: HttpService, private _sanitizer: DomSanitizer, private route: ActivatedRoute, public megamenuService: MegamenuService) {}

  ngOnInit(){
   this.checkStorageForData();
  }

  checkStorageForData() {
    const lastCacheDate = localStorage.getItem("SUBJECTAREAS_BYNAME_LAST_CACHE_DATE");
    if(lastCacheDate) {
      const startTime = new Date(lastCacheDate);
      const endTime = new Date();
      const difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
      const resultInMinutes = Math.round(difference / 60000);

      if(resultInMinutes > 60) {
        this.getSubjectAreas();
        return;
      } else {
        const subjectAreasString = localStorage.getItem("SUBJECTAREAS_BYNAME_VALUE");

        const adlist = localStorage.getItem("USERS_AZURE_ADGROUPS") as string;
        this.megamenuService.userAzureAdGroups = JSON.parse(adlist) as string[];

        if(subjectAreasString) {
          this.httpService.megamenuLoading = false;
         this.subjectAreas = JSON.parse(subjectAreasString) as SubjectArea[];
         this.subjectAreas = this.sortList(this.subjectAreas);
         this.subjectAreas.forEach((subjectArea) => {
           subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
           + subjectArea.image);
          });
          if(this.subjectAreas) {
            this.selectedSubjectArea = this.subjectAreas[0];
          }
        }
        return;
      }


  }
  this.getSubjectAreas();
}

sortList(subjectAreas: SubjectArea[]): SubjectArea[] {
  const subjectAreasSorted = subjectAreas.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
  return subjectAreasSorted;
}

getSubjectAreas() {
  try {
    //get values here
    this.httpService.getSubjectAreas('').subscribe(data => {
      this.subjectAreas = data;

      this.subjectAreas.forEach((subjectArea) => {
       subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
       + subjectArea.image);
      });

      if(this.subjectAreas) {
        this.selectedSubjectArea = this.subjectAreas[0];
        const now = new Date();
        localStorage.setItem("SUBJECTAREAS_BYNAME_LAST_CACHE_DATE", now.toString() );
        localStorage.setItem("SUBJECTAREAS_BYNAME_VALUE", JSON.stringify(data) );
      }

    });
  } catch (error: any) {
    // Prepare status message for Embed failure

    const displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
    console.error(displayMessage);
    return;
  }
}


  setReportId(reportId: string) {
    this.currentViewedReportId = reportId;
  }

  subjectAreaSelected(selectedsubjectArea: SubjectArea) {
  this.selectedSubjectArea = selectedsubjectArea;
 }

 categorySelected(selectedCategory: Category) {
  this.selectedCategory = selectedCategory;
 }
}
